/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'luggage-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V5h.5A1.5 1.5 0 018 6.5V7H7v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v7a.5.5 0 00.5.5H4v1H2.5v.25a.75.75 0 01-1.5 0v-.335A1.5 1.5 0 010 13.5v-7A1.5 1.5 0 011.5 5H2zM3 5h2V2H3z"/><path pid="1" d="M2.5 7a.5.5 0 01.5.5v5a.5.5 0 01-1 0v-5a.5.5 0 01.5-.5m10 1v-.5A1.5 1.5 0 0011 6h-1a1.5 1.5 0 00-1.5 1.5V8H8v8h5V8zM10 7h1a.5.5 0 01.5.5V8h-2v-.5A.5.5 0 0110 7M5 9.5A1.5 1.5 0 016.5 8H7v8h-.5A1.5 1.5 0 015 14.5zm9 6.5V8h.5A1.5 1.5 0 0116 9.5v5a1.5 1.5 0 01-1.5 1.5z"/>',
    },
});
